<template>
  <section class="cards-continer" v-if="vEntorno" >
    <!-- <div v-for="(item, index) in vEntorno.events" :key="'CardSite' + index" @click="openSite(item, index)">
      <div class="cards-order" v-if="item.showRecommended">
        <MiniCardSite class="site-card-style"  :RibonPosition="2" :item="item" :index="index" :miniFlag="true"/>
      </div>
    </div> -->
    <v-slide-group
      dark
      class="pa-4"
      active-class="success"
      show-arrows       
      center-active>
      <v-slide-item
        v-for="(item, index) in vEntorno.events"
        :key="'CardSite' + index" >
      
          <MiniCardSite 
            v-show="item.showRecommended" 
            @click.native="openSite(item, index)"
            class="site-card-style my-5 mx-0 mx-md-2 mx-lg-2"  
            :RibonPosition="2" 
            :item="item" 
            :index="index" 
            :miniFlag="true"/>

      </v-slide-item>
    </v-slide-group>
    
  </section>
</template>

<script>
import { SITES_FUNCTIONS } from '@/mixins/sites-mixin';

import MiniCardSite from '../components/Home/CardSite.vue';

export default {
  name: 'RecommendationsFooter',
  components: {
    MiniCardSite
  },
  mixins: [SITES_FUNCTIONS],
  data() {
    return {      
      vEntorno: null,
      item: null,
      page: 1,
    }
  },
  created() {
    this.vEntorno = this.$vEntorno;
    this.Inputnumeric = this.$Inputnumeric;
  },
  mounted() {
    this.vEntorno = this.$vEntorno;
    this.Inputnumeric = this.$Inputnumeric;    
  }
}
</script>

<style scoped>
.cards-continer {
  width: 100%;
  display: flex;
  justify-content: center;
  max-width: 1200px;
  margin: 0px auto;
  padding-bottom: 20px;
  overflow: hidden;
  overflow-x: auto;
}
.cards-order {
  padding: 0px 10px;
}
.site-card-style {
  min-width: 250px;
}

</style>