<template>
  <div>
    <v-card 
      width="100%" 
      color="rgba(255, 255, 255, 0.05)" 
      class="px-2 pb-6 pt-md-3 pt-lg-3 card-resume-style"
      :class="[{'black--text': !$store.state.corpInfo.darkTheme}, {'white--text': $store.state.corpInfo.darkTheme}]">
      <v-card-title class="title-resume text-uppercase">{{ $t("actions.resume") }}</v-card-title>
      <v-card-text :class="[{'black--text': !$store.state.corpInfo.darkTheme}, {'white--text': $store.state.corpInfo.darkTheme}]">
        <v-row class="px-5 mt-0" align="center">
          <v-col cols="6" class="text-left">
            <span class="text-uppercase">
              {{ $t("actions.cardinfo.total") }}
            </span>            
          </v-col>
          <v-col cols="6" class="text-right card-total-style">
            <span class="">
              $ {{ $root.formatPrice( $store.getters['shopping/getCroosedTotal'] ) }}
            </span> 
            <span class="text-caption">
              MXN
            </span>         
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions v-if="$store.getters['shopping/getNumberCrossed'] > 0" class="px-8">
        <div @click.stop="Pay()" class="btnPay mx-auto">
          {{ $t("actions.shop") }}
        </div>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
  export default {
    name: 'ShoppingCartResume',
    data() {
      return {

      }
    },
    methods: {
      Pay() {
        return this.$root.swalAlert('warning', this.$t("sales.serviceUnavailableMessage"));
        // this.$emit('confirmPay', "openPayStepper");
      }
    }
  }
</script>

<style scoped>
.btnPay {
  border: 1px solid rgba(255, 255, 255, 0.774);
  /* background-color: black; */
  padding: 20px 10px;
  box-shadow: 0px 0px 5px 1px rgb(255, 255, 255);
  color: white;
  font-weight: 600;
  width: 100%;
  cursor: pointer;
  transition: all .5s ;
  text-align: center;
  background-color: var(--color-primary) !important;
  color: var(--white) !important;
}

.btnPay:hover {
  border: 1px solid white;
  box-shadow: 0px 0px 10px 3px white;
  color: white;
  width: 100%;
}
.card-resume-style {
  border: 1px solid rgba(255, 255, 255, 0.6) !important;
}
.title-resume{
  font-size: 20px;
}
.card-total-style {  
  font-size: 20px;
}
@media (max-width: 960px) {
  .btnPay {
    padding: 10px 10px;
    width: 50%;
  }
  .title-resume{
    font-size: 14px;
  }
  .card-total-style {  
    font-size: 19px;
  }
}
</style>