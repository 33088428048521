<template>
  <div v-show="showStepper==true">
    <div class="fullDvi">
      <div @click="detectClick($event)" align="center">

        <v-stepper class="pa-0"
            v-model="e1" 
            :value="e1" 
            style="max-width: 1400px;"            
            :style="[ 
              {'background-color': $store.state.corpInfo.darkTheme?'rgb(0 0 0 / 0.3) !important':'rgb(255 255 255 / 0.3) !important'}, 
              {'backdrop-filter': 'blur(2px)'} 
            ]"
            :dark="$store.state.corpInfo.darkTheme">
          <v-stepper-header>

            <v-divider></v-divider>
            <v-stepper-step
              color="var(--color-primary)"
              v-show="showUserDataStep===true"
              :complete="e1 > 1"
              :step="`${showUserDataStep === true ? 1: 0}`"
              :editable="changeSteps.validPriStep"
            >
              {{$t("actions.step.usuario")}}
            </v-stepper-step>

            <v-divider v-if="!processExclusive && showUserDataStep"></v-divider>

            <v-stepper-step
              color="var(--color-primary)"
              v-if="!processExclusive"
              :step="`${showUserDataStep === true ? 2: 1}`"   
              > 
              {{$t("actions.step.confirma")}} 
            </v-stepper-step>
            <v-divider></v-divider>
          </v-stepper-header>

          <v-stepper-items>
         
            <v-stepper-content v-show="showUserDataStep === true && e1 == 1" editable 
              :step="`${showUserDataStep === true ? 1: 0}`" class="py-0 px-10">
              <v-card flat class="transparent">
                <v-card-text>
                  <FormClient
                    ref="FormUserData"
                    v-if="showUserDataStep"
                    v-show="showFormClient && showUserDataStep === true && e1 == 1"
                    @ValidationUserForm="ValidationUserForm"
                    @editUserInfo="editUserInfo"
                    :editInfo="editUserData"
                  />
                </v-card-text>
                <v-card-actions class="pb-3" v-show="showUserDataStep===true && e1 == 1">
                  <v-btn 
                      v-if="editUserData === true && fileEmpty === false" 
                      color="black" dark
                      @click="editUserData = false;"> {{$t("actions.goBack")}} 
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn color="var(--color-primary)" 
                      :dark="$store.state.corpInfo.darkTheme"  
                      @click="NextStep(1)">
                        {{$t("actions.next")}}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-stepper-content>

            <v-stepper-content
                editable 
                complete
                :step="`${showUserDataStep === true ? 2: 1}`"
                v-show="(showUserDataStep === true && e1 == 2) || (showUserDataStep === false && e1 == 1)"
                class="py-0 px-10">
              <v-card flat class="transparent pa-0"
                v-if="!processExclusive">
                <v-card-text>
                  <CardForm
                    ref="CardFormularioCultur"
                    @validationCardSuccessful="acceptedCardValid"
                    @validationCardDenied="rejectedCardValid"
                  />
                </v-card-text>
                <v-card-actions class="pb-3">
                  <v-btn color="black" dark @click="GoBack()"> {{$t("actions.goBack")}} </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn color="var(--color-primary)" 
                      :dark="$store.state.corpInfo.darkTheme" @click="NextStep(2)" :loading="loadingBtn">
                    {{$t("actions.step.confirma1")}} 
                  </v-btn>
                </v-card-actions>
              </v-card>
              <v-card v-else
                min-height="350"
                class="pa-0 transparent d-flex justify-center align-center"
              >
                {{ $t('messages.generateorden') }}...
              </v-card>
            </v-stepper-content>  
            
          </v-stepper-items>
        </v-stepper>
      </div>
    </div>
  </div>
</template>

<script>
import FormClient from "../netpay/Formulario.vue";
import CardForm from "../netpay/UserCardForm.vue";
import { USER_DATA } from '@/mixins/user-mixin'
import { PURCHASE_FUNCTIONS } from '@/mixins/purchase-mixin'
// import { PURCHASE_SIMULATION_FUNCTIONS } from '@/mixins/purchaseSimulation-mixin'

export default {
  components: {
    FormClient,
    CardForm
  },
  mixins: [
    USER_DATA,
    PURCHASE_FUNCTIONS
    // PURCHASE_SIMULATION_FUNCTIONS
  ],
  data() {
    return {
      e1: 1,
      validSecondStep: true,
      showFormClient: true,
      changeSteps: {
        validPriStep: true,
        validSegStep: false,
      },
      tokenCard : null,
      editUserData: false,
      fileEmpty: false,
      showUserDataStep: false,
      showStepper: false,
      loadingBtn: false,
      processExclusive: false
    };
  },
  created() {
    this.Inputnumeric = this.$Inputnumeric;
    this.vEntorno = this.$vEntorno;
  },
  mounted() {
    this.processExclusive = this.$store.getters['shopping/getCroosedTotal'] == 0;
    this.loadingBtn = false
    this.Inputnumeric = this.$Inputnumeric;
    this.vEntorno = this.$vEntorno;
    this.$root.loading = false;
    this.LoadUserData();
  },
  beforeMount(){
    this.Inputnumeric = this.$Inputnumeric;
  },
  methods: {
    ValidationUserForm(value) {
      if (value === true) {
        this.updateStepValidation(1, true);
        if(this.processExclusive) this.benefitCustomPay()
      } else {
        this.updateStepValidation(1, false);
        this.$root.swalAlert(
          "warning",
          "",
          this.$t("messages.checkTheFormData")
        );
        return false;
      }
    },
    LoadUserData() {
      this.getUserDataMix()
      .then((response) => {
        if(response.calle === null || response.calle == "") {
          this.showUserDataStep = true;
          setTimeout(() => {
            this.$refs.FormUserData.loadCityAndState();
          }, 200)
        }
        this.showStepper = true        
        this.updateStepValidation(0, true);
      }, 
      (error) => {
        this.$emit("NoShowSteeper", false)
      })
      .catch(e => {
        this.$root.swalAlert('error', e.message);
      });
    },
    updateStepValidation(noStep, success) {      
      if(this.processExclusive && !this.showUserDataStep) this.benefitCustomPay()

      switch (noStep) {
        case 1:
          if (success === true) {
            this.changeSteps.validPriStep = true;
            this.changeSteps.validSegStep = true;
            
            if (this.showFormClient === false) {
              this.showAddCardButton = true;
            }
            this.e1 = 2;
            
            if(this.processExclusive && !this.showUserDataStep) this.benefitCustomPay();
          } else {
            this.changeSteps.validPriStep = false;
            this.changeSteps.validSegStep = false;
          }
          break;
        case 2:
          if (success === true) {
            this.changeSteps.validPritStep = true;
            this.changeSteps.validSegStep = true;
            this.validSecondStep = true;
            this.showAddCardButton = false;
            this.e1 = 3;
          } else {
            this.changeSteps.validPriStep = false;
            this.validSecondStep = false;
          }
          break;
        case 3:
          if (success === true) {
            this.e1 = 4;
          } else {
            this.changeSteps.validPriStep = false;
            this.validSecondStep = false;
          }
          break;
      }
    },
    NextStep(page) {
      return this.$root.swalAlert('warning', this.$t("sales.serviceUnavailableMessage"));
      /*switch (page) {
        case 1:
          this.gotoNetPay();
          break;
        case 2:
          this.$root.loading = true;   
          this.$vEntorno.textloading = this.$t('messages.paymentProcess.validateCard'); 
          this.loadingBtn = true                 
          setTimeout(() => {
            this.SaveUserData();
          }, 150)          
          break;
      }*/
    },
    acceptedCardValid(responseToken, userCardData) {
      return this.$root.swalAlert('warning', this.$t("sales.serviceUnavailableMessage"));
      this.loadingBtn = false;
      this.tokenCard = responseToken;
      this.Pay(this.tokenCard, userCardData);
    },
    rejectedCardValid() {
      return this.$root.swalAlert('warning', this.$t("sales.serviceUnavailableMessage"));
      this.$root.loading = false;
      this.loadingBtn = false;
      this.updateStepValidation(2, false);
    },
    gotoNetPay() {
      return this.$root.swalAlert('warning', this.$t("sales.serviceUnavailableMessage"));
      this.$refs.FormUserData.Save();
    },
    SaveUserData() {
      this.$refs.CardFormularioCultur.ValidateCard();
    },
    GoBack() {
      return this.$root.swalAlert('warning', this.$t("sales.serviceUnavailableMessage"));
      this.e1 = parseInt(this.e1);
      switch (this.e1) {
        case 1:
          this.$emit("NoShowSteeper")
          break;
        case 2:
          this.e1 = 1;
          break;
        case 3:
          this.e1 = 2;
          break;
        case 4:
          this.e1 = 3;
          break;
      }
    },
    Pay(tokenCard, cardUserDetails) {
      return this.$root.swalAlert('warning', this.$t("sales.serviceUnavailableMessage"));
      this.loadingBtn = true;
      this.$root.loading = true;
      
      let purchaseData = {
        id_corp: this.$root.id_corp,
        id_event: 0,
        amount: parseFloat(this.$store.getters['shopping/getCroosedTotal']),
        cantidad: parseInt(this.$store.getters['shopping/getNumberCrossed']),
        id_place: 0,
        orden: null,
        event_config: 3,
        tickets: this.$store.state.shopping.crossSellTickets,
        tokenTem: null,
        credential_id: this.$store.state.sites.credential_id,
        stadisticFlag: this.$store.state.sites.statistics,
        stadisticOptions: this.$store.state.shopping.stadisticsValue
      }

      let dataJSON = {
        transactionType: 'Auth',
        sessionId: tokenCard.deviceFingerPrint,
        deviceFingerPrint: tokenCard.deviceFingerPrint,
        amount: parseFloat(this.$store.getters['shopping/getCroosedTotal']),
        description: "Venta cruzada",
        source: tokenCard.token,
        paymentMethod: "card",
        currency: "MXN",
        billing: {
          firstName: cardUserDetails.firstName,
          lastName: cardUserDetails.lastName,
          email: this.$store.state.auth.userData.correo,
          phone: this.$store.state.auth.userData.celular,
          merchantReferenceCode: null,
          address: {
            city: cardUserDetails.city,
            country: cardUserDetails.country,
            postalCode: cardUserDetails.postalCode,
            state: cardUserDetails.state,
            street1: cardUserDetails.street,
            street2: "SN",
          },
        },
        redirect3dsUri: "http://example.com",
        saveCard: 'false',
      };

      this.initTransacTokenMix()
      .then(
        (response) => {
          if(response.success === 'TRUE') {
            purchaseData.tokenTem = response.encoded;
            dataJSON.billing.merchantReferenceCode = response.encoded;
            this.startPurchaseMix(purchaseData, dataJSON);
          }
          else {            
            this.$root.loading = false; 
            this.$root.swalAlert('error', "", this.$t("sales.failurePaymentMessages.startNewShopMessage"));
          }
        },
        (error) => {
          this.$root.loading = false;
          if (error.response && error.response.status !== 401) {
            this.$root.swalAlert('error', this.$t("sales.failurePaymentMessages.startNewShopMessage"), error.message);
          } 
          if(error.success === 'FALSE') {
            this.$root.swalAlert('error', this.$t("sales.failurePaymentMessages.startNewShopMessage"), error.message);
          }
        }
      ).finally( () => {
        this.loadingBtn = false;
      })
    },
    benefitCustomPay() {
      try {
        return this.$root.swalAlert('warning', this.$t("sales.serviceUnavailableMessage"));
        this.loadingBtn = true;
        this.$root.loading = true;
        
        let purchaseData = {
          id_corp: this.$root.id_corp,
          id_event: 0,
          amount: parseFloat(this.$store.getters['shopping/getCroosedTotal']),
          cantidad: parseInt(this.$store.getters['shopping/getNumberCrossed']),
          id_place: 0,
          orden: null,
          event_config: 3,
          tickets: this.$store.state.shopping.crossSellTickets,
          tokenTem: null,
          credential_id: this.$store.state.sites.credential_id,
          stadisticFlag: this.$store.state.sites.statistics,
          stadisticOptions: this.$store.state.shopping.stadisticsValue
        }

        this.initTransacTokenMix()
        .then((response) => {
          if(response.success === 'TRUE') {
            purchaseData.tokenTem = response.encoded;
            this.paymentExclusiveMix(purchaseData, this.processExclusive);
          } 
          else {
            this.loadingBtn = true;
            this.$root.loading = true;
            this.$root.swalAlert('error', "", this.$t('sales.netpayErrorMessages.payError'));
          }
        },
        (error) => {
          if (error.response && error.response.status !== 401) {
            this.$root.swalAlert('error', error.message);
          } 
          if(error.success === 'FALSE') {
            this.$root.swalAlert('error', error.message);
          }
        })
        .finally( () => {
          this.loadingBtn = false;
        })
      } catch(e) {
        this.loadingBtn = false;
      }
    },
    editUserInfo(val) {
      this.editUserData = true;
      if(val === 1) {
        this.fileEmpty = true;
      }
    },
    detectClick(val) {
      if(val.target.id != "inputCardCv") {        
        if(this.$refs.CardFormularioCultur) {
          this.$refs.CardFormularioCultur.flipCard();
        }        
      }      
    },
  },
};
</script>

<style>
  .totalX{
    background: rgb(226, 226, 226);
    text-align: right;
    font-size: 14px;
    padding-right: 20px;
    font-weight: 600;
    width: 40%;
    height: 48px;
    display: flex;
    flex-direction: row-reverse;
    align-content: center;
    align-items: center;
  }
  .fullDvi{
    height: 100%;
  }
</style>