<template>
  <div id="PaginationCart">
    <v-divider class="mt-4 show-divider" color="#464646"></v-divider>
    <v-card flat v-if="items.length >= 0" style="background-color: transparent !important; max-height:700px;">
      <v-card-title class="text-uppercase" 
        :class="[{'black--text': !$store.state.corpInfo.darkTheme}, {'white--text': $store.state.corpInfo.darkTheme}]">
        {{ $t("actions.shoppingCart") }}
      </v-card-title>
      <v-card-subtitle :class="[{'black--text': !$store.state.corpInfo.darkTheme}, {'white--text': $store.state.corpInfo.darkTheme}]" 
        class="text-left text-caption" v-if="$root.token">
        {{ $root.token.users }}
      </v-card-subtitle>
      <div class="line-divider" :class="[{'light': !$store.state.corpInfo.darkTheme}]"></div>           
      <v-data-table
        :dark="$store.state.corpInfo.darkTheme"
        style="background-color: transparent !important;"
        :style="[{'color': $store.state.corpInfo.darkTheme?'white':'black'}]"
        :headers="headers"
        :items="items"
        :page.sync="page"
        :items-per-page="4"
        @page-count="pageCount = $event"
        hide-default-footer
        v-if="vEntorno"
        class="elevation-0 table-size">

        <template v-slot:item="{ item }">
          <tr :class="[{'black--text': !$store.state.corpInfo.darkTheme}, {'white--text': $store.state.corpInfo.darkTheme}]">
            <td class="text-center ">
              <v-img
                :src="$root.pathevens + item.img"
                style="max-width: 140px;
                max-height: 80px;
                margin: 5px;
                border-radius: 5px;">
              </v-img>
            </td>
            <td class="text-center">
              {{ item.eventname[$root.getTextlang()] }}
            </td>
            <td class="text-center">
              {{ item.alias2[$root.getTextlang()] }}
            </td>
            <td class="text-center">
              <v-container class="input-cantidad">
                <v-icon class="button-shop" @click="modifyQuantityTickets(item, -1)">
                  mdi-minus
                </v-icon>
                <input
                  v-model="item.cantidad"
                  class="MyInputNumber"
                  maxlength="3"
                  @input="checkit(item)"
                  @blur="verifyEmptyFile(item)"
                  type="text"
                  v-mask="'###'"
                />
                <v-icon class="button-shop" @click="modifyQuantityTickets(item, 1)">
                  mdi-plus
                </v-icon>
              </v-container>
            </td>
            <td class="text-center">
              ${{ $root.formatPrice(item.price) }}
            </td>
            <td class="text-center">
              <v-icon
                :color="$store.state.corpInfo.darkTheme ? 'white':'black'"
                @click="removeTicket(item)"
              >
                mdi-delete
              </v-icon>
            </td>
          </tr>
        </template>
      </v-data-table>
      <div class="text-center pt-2">
        <v-pagination
          color="var(--color-primary)"
          :dark="$store.state.corpInfo.darkTheme"
          v-model="page"
          :length="pageCount">
        </v-pagination>
      </div>
    <div class="line-divider" :class="[{'light': !$store.state.corpInfo.darkTheme}]"></div>
    </v-card>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'ShoppingCartTable',
  props: ['tickets'],
  data() {
    return {
      vEntorno: null,
      headers: [
        {
          align: 'center',
          sortable: false,
          value: 'img',
        },
        { text: this.$t("actions.place"), 
          value: 'sitio', 
          class: `text-subtitle-2 text-uppercase`, 
          align: 'center'
        },
        { text: this.$t("actions.cardinfo.tickets"), 
          value: 'alias', 
          class: `text-subtitle-2 text-uppercase`, 
          align: 'center'
        },
          { text: this.$t("actions.amount"), 
          value: 'cantidad', 
          class: `text-subtitle-2 text-uppercase`, 
          align: 'center'
        },
          { text: this.$t("actions.cardinfo.unitPrice"), 
          value: 'price', 
          class: `text-subtitle-2 text-uppercase`, 
          align: 'center'
        },
          { value: 'actions', 
          sortable: false, 
          class: `text-subtitle-2 text-uppercase`, 
          align: 'center' 
        },
      ],
      items: [],
      page: 1,
      pageCount: 0,
    }
  },
  mounted() {
    this.vEntorno = this.$vEntorno;
    this.items = this.$store.state.shopping.crossSellTickets;
  },
  methods: {
    ...mapActions({
      upgradeShoppingCart: 'shopping/updateCrossPurchase'
    }),
    modifyQuantityTickets(val, sum) {
      let whereami = this.items.indexOf(val);
      let cantidadTemp = parseInt(this.items[whereami].cantidad) + sum;
      if(cantidadTemp <= 20 && cantidadTemp >= 1) {
        this.items[whereami].cantidad = parseInt(this.items[whereami].cantidad) + sum;
        this.items[whereami].total = parseInt(this.items[whereami].price) * parseInt(this.items[whereami].cantidad);
      }
    },
    removeTicket(item) {
      let whereami = this.items.indexOf(item);
      this.items[whereami].cantidad = 0;
      this.items[whereami].total = 0;
      this.items.splice(whereami, 1);
    },
    checkit(val) {
      let whereami = this.items.indexOf(val);
      if(parseInt(val.cantidad) > 20) {
        this.items[whereami].cantidad = 20;
      }
      if(parseInt(val.cantidad) < 1) {
        this.items[whereami].cantidad = 1;
      }
      this.items[whereami].total = parseInt(this.items[whereami].price) * parseInt(this.items[whereami].cantidad);
    },
    verifyEmptyFile(item){
      if(item.cantidad.length === 0) {
        let whereami = this.items.indexOf(item);
        this.items[whereami].cantidad = 1;
        this.items[whereami].total = parseInt(this.items[whereami].price) * parseInt(this.items[whereami].cantidad);
      }
    }
  }
}
</script>

<style scoped>
.input-cantidad {
  border: .5px solid white !important;
  border-radius: 25px;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 5px 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  min-height: 45px;
  max-width: 130px;
}

.input-cantidad input {
  color: white !important;
}
.button-shop {
  cursor: pointer;
  color: white;
}
.button-shop:hover {
  color: var(--color-primary);
}
.MyInputNumber {
  font-size: 15px;
  padding: 3px;
  border: none;
  max-width: 60px;
  text-align: center;
  height: 30px !important;
  margin-top: 0px;
  font-weight: 400;
}
tbody tr:hover {
  background-color: transparent !important;
}
.line-divider {
  width: 100%;
  border: #ffffffcc 1px solid ;
}.light {
  border: #000000cc 1px solid ;
  color: black;
}
.show-divider {
  display: none;
}
.table-size{
  /* min-height: 450px; */
  max-height: 460px;
}
@media (max-width: 959px) {
  .show-divider {
    display: block;
  }
}
</style>